export const Networks = {
    "56": "BSC TESTNET",
    "109090": "CASPER TESTNET"
}

export const contractInfo = [
    {
        "token": "d7568061ba70693e8af4e996a321e747aa17999af102bdea844196e52e592f94",
        "contract-hash": "2c3434a440304f8edcc4aaa4b9be52b908a53f8299c70af54dee572831a24b31"
    }
]