import React, { useEffect, useState } from "react";
import { FCard, FGrid, FGridItem, FItem, FTypo } from "ferrum-design-system";
import { useSelector } from "react-redux";
import { getStakingInfo } from "../utils/DateUtil";


export const StakingInfoCard = () => {

  // const [stakingCap, setStakingCap] = useState<any>(undefined);
  const [stakeSoFar, setStakeSoFar] = useState<any>(undefined);
  const [youStakedBalance, setYourStakedBalance] = useState<any>(undefined);
  const { connect: { config, selectedAccount, isWalletConnected, signedAddresses, tokenInfo} } = useSelector((state: any) => state.casper);

  // console.log(stakingCap, stakeSoFar, youStakedBalance);
  console.log(config, 'configconfigconfig');
  const closesIn = getStakingInfo(config?.stakingEnds, config?.stakingStarts, config?.withdrawStarts, config?.withdrawEnds).stakingClosesIn;
  const isEarlyWithdraw = getStakingInfo(config?.stakingEnds, config?.stakingStarts, config?.withdrawStarts, config?.withdrawEnds).isEarlyWithdraw;
  const isStakingOpen = getStakingInfo(config?.stakingEnds, config?.stakingStarts, config?.withdrawStarts, config?.withdrawEnds).isStakingOpen;
  const isWithdrawOpen = getStakingInfo(config?.stakingEnds, config?.stakingStarts, config?.withdrawStarts, config?.withdrawEnds).isWithdrawOpen;

  return (
    <FCard className={"card-staking f-mb-2"}>
      <FGrid>
        <FGridItem alignX={"center"} className="f-mb-1">
          <FItem align={"center"}>
            <FTypo size={18} align={"center"} className={"f-mb--5 f-mt--7"}>
              STAKING CAP
            </FTypo>
            <FTypo size={22} weight={600} color="#dab46e">
              {config?.stakingTotal || 0} {tokenInfo.tokenSymbol || "token"}
            </FTypo>
          </FItem>
        </FGridItem>
        <FGridItem>
        {
          (isEarlyWithdraw && !isStakingOpen) &&
            <FItem
                bgColor="#1F2128"
                className={"f-mt--5 f-p--8 w-100"}
                align="center"
              >
                <FTypo weight={500} size={18}>
                  STAKING CONTRIBUTION CLOSED
                </FTypo>
              </FItem>
        }
        {
          (isWithdrawOpen) &&
            <FItem
                bgColor="#1F2128"
                className={"f-mt--5 f-p--8 w-100"}
                align="center"
              >
                <FTypo weight={500} size={18}>
                  WITHDRAW OPEN
                </FTypo>
              </FItem>
        }
        {
          isStakingOpen &&
            (
            <FItem
                bgColor="#1F2128"
                className={"f-mt--5 f-p--8 w-100"}
                align="center"
            >
                <FTypo weight={500} size={18}>
                STAKING CONTRIBUTION CLOSE IN {closesIn || 0}
                </FTypo>
            </FItem>
          )
        }
        </FGridItem>
      </FGrid>
    </FCard>
  );
};

export default StakingInfoCard;
